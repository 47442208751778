import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';

export interface RouterUrl {
  routerLink: string;
  queryParams?: object;
}

export interface ExternalUrl {
  url: string;
}

export type NormalizedUrl = ExternalUrl | RouterUrl;

@Injectable({ providedIn: 'root' })
export class UrlResolverService {
  private readonly document = inject(DOCUMENT);

  normalizeUrl(rawUrl: string): NormalizedUrl {
    if (!rawUrl) {
      return { url: rawUrl };
    }
    const url = new URL(rawUrl, this.document.location.origin);

    if (url.host === this.document.location.host) {
      return url.search
        ? {
            routerLink: decodeURIComponent(url.pathname),
            queryParams: Object.fromEntries(url.searchParams),
          }
        : { routerLink: decodeURIComponent(url.pathname) };
    }

    return { url: rawUrl };
  }
}
