import { DOCUMENT } from '@angular/common';
import { computed, inject, Injectable, signal } from '@angular/core';

import { UserService } from '@ppg/auth';
import { LoggerService } from '@ppg/core/logger';

const IS_MOBILE_APP_QUERY_PARAM = 'isMobile';

@Injectable({ providedIn: 'root' })
export class MobileAppDetectorService {
  #document = inject(DOCUMENT);
  #loggerService: LoggerService = inject(LoggerService);
  #userService = inject(UserService);

  #isMobileAppFromUrl = signal(this.identifyMobileAppFromUrl());
  #isMobileAppFromUserInfo = this.#userService.isAuthenticatedOnMobileDevice;

  readonly isMobileApp = computed(() => {
    const isMobileAppFromUrl = this.#isMobileAppFromUrl();
    const isMobileAppFromUserInfo = this.#isMobileAppFromUserInfo();
    const isMobileApp = isMobileAppFromUrl || isMobileAppFromUserInfo;
    this.#loggerService.info(
      `[MobileAppDetectorService] New isMobileApp state: ${JSON.stringify({ isMobileApp, isMobileAppFromUrl, isMobileAppFromUserInfo })}.`,
    );
    return isMobileApp;
  });

  private identifyMobileAppFromUrl(): boolean {
    const { searchParams } = new URL(this.#document.location.href);
    const isMobileQueryParam = searchParams.get(IS_MOBILE_APP_QUERY_PARAM);

    if (isMobileQueryParam) {
      this.#loggerService.info(
        `[MobileAppDetectorService] Found isMobile query param with value: ${isMobileQueryParam}`,
      );
    }
    return isMobileQueryParam === 'true';
  }
}
