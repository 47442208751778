import { DestroyRef, inject, ɵPendingTasks as PendingTasks } from '@angular/core';

export interface PendingTaskWatcher {
  startTask: () => void;
  stopTask: () => void;
}

export function createPendingTaskWatcher(): PendingTaskWatcher {
  const pendingTask = inject(PendingTasks);
  const destroyRef = inject(DestroyRef);
  let currentTaskId: number | null = null;

  const stopTask = (taskId: number | null) => {
    if (taskId !== null) {
      pendingTask.remove(taskId);
    }
  };

  destroyRef.onDestroy(() => stopTask(currentTaskId));

  return {
    startTask: () => {
      const previousTask = currentTaskId;
      currentTaskId = pendingTask.add();
      stopTask(previousTask);
    },
    stopTask: () => stopTask(currentTaskId),
  };
}
