import { Platform } from '@angular/cdk/platform';
import { inject } from '@angular/core';

import { LoggerService } from '@ppg/core/logger';

export abstract class BaseLocalStorageService<T> {
  #platform = inject(Platform);
  #loggerService = inject(LoggerService);
  protected abstract readonly localStorageKey: string;

  setInLocalStorage(value: T): void {
    if (this.#platform.isBrowser) {
      localStorage.setItem(this.localStorageKey, JSON.stringify(value));
    }
  }

  getFromLocalStorage(): T | null {
    if (!this.#platform.isBrowser) {
      return null;
    }

    const stringItem = localStorage.getItem(this.localStorageKey);

    if (!stringItem) {
      return null;
    }

    try {
      const item: T = JSON.parse(stringItem);
      return item;
    } catch (error) {
      this.#loggerService.error(
        `[BaseLocalStorageService][getFromLocalStorage] Error occurred while parsing local storage data: ${(error as Error).message}.`,
      );
      // Clear storage to recover from this error
      this.removeFromLocalStorage();

      return null;
    }
  }

  removeFromLocalStorage(): void {
    if (this.#platform.isBrowser) {
      localStorage.removeItem(this.localStorageKey);
    }
  }

  clearLocalStorage(): void {
    if (this.#platform.isBrowser) {
      localStorage.clear();
    }
  }
}
