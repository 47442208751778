import { Injectable } from '@angular/core';

import { ContainerContentBannerPresentation, ContainerContentBannerTheme, ContentComponentType } from '@ppg/core/enums';
import { ContainerContentBanner, ContainerContentBannerContent, PageContent } from '@ppg/core/models';

type HasMainContentArea = Extract<PageContent, { mainContentArea: unknown }>;

@Injectable({ providedIn: 'root' })
export class ContentBannerThemeService {
  processContentBannerTheme(pageContent: PageContent): PageContent {
    if (!this.#hasMainContentArea(pageContent)) return pageContent;

    const mainContentArea = [...pageContent.mainContentArea];
    let cardsAmountInUpdatableBanners = 0;

    mainContentArea.forEach((item, index) => {
      if (item.type === ContentComponentType.CONTAINER_CONTENT_BANNER && this.#shouldUpdateBannerTheme(item)) {
        mainContentArea[index] = this.#updateBannerOrderTheme(item, cardsAmountInUpdatableBanners);

        cardsAmountInUpdatableBanners += item.content.cards.length;
      }
    });

    return {
      ...pageContent,
      mainContentArea,
    } as PageContent;
  }

  #hasMainContentArea(pageContent: PageContent): pageContent is HasMainContentArea {
    return 'mainContentArea' in pageContent;
  }

  #updateBannerOrderTheme(item: ContainerContentBanner, cardAmount: number): ContainerContentBanner {
    return {
      ...item,
      content: {
        ...(item.content as ContainerContentBannerContent),
        orderForDefaultTheme: this.#getContentBannerOrderTheme(cardAmount),
      },
    };
  }

  #shouldUpdateBannerTheme(item: ContainerContentBanner): boolean {
    return (
      (item.content.presentation === ContainerContentBannerPresentation.HORIZONTAL_STANDARD_50 ||
        item.content.presentation === ContainerContentBannerPresentation.HORIZONTAL_STANDARD ||
        item.content.presentation === ContainerContentBannerPresentation.HORIZONTAL_SHORT ||
        item.content.presentation === ContainerContentBannerPresentation.HORIZONTAL_TALL) &&
      item.content.theme === ContainerContentBannerTheme.DEFAULT
    );
  }

  #getContentBannerOrderTheme(cardsAmountBefore: number): number {
    return cardsAmountBefore % 3;
  }
}
